import React from 'react';
import {H0, P1, Text} from '../style/Typography';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import TextLink from '../components/TextLink';
import imgHero from '../images/hero-logo-raster.png';
import imgChrome from '../images/chrome_128x128.png';
import imgFirefox from '../images/firefox_128x128.png';
import imgSafari from '../images/safari_128x128.png';
import {typography} from '../style/theme';

const Layout = styled.div``;

const Copy = styled.div`
  width: 75%;
  max-width: 720px;
  margin: 80px auto 20px auto;

  & > h1 {
    margin: 43px 0 30px 0;
  }
  & > p {
    margin: 24px 0;
  }
`;

const Hero = styled.img`
  width: 233px;
  margin-bottom: 20px;
`;

const BrowserTable = styled.table`
  margin: 60px auto;
  width: 92%;
  max-width: 876px;
  text-align: center;
  td {
    border-left: #e5e5e5 solid 1px;
  }
  td:first-child {
    border: none;
  }
`;

const BrowserImg = styled.img`
  width: 96px;
`;

const browserLink = styled(TextLink).attrs({
  color: 'green',
  openInNewWindow: true,
})`
  ${typography.p1}
`;

export default (props: any) => {
  return (
    <Layout>
      <Helmet>
        <title>ReusePlastics.org is not optimized for Internet Explorer</title>
        <meta
          name="description"
          content="ReusePlastics.org is not optimized for Internet Explorer"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Text color="charcoal">
        <Copy>
          <Hero src={imgHero} />
          <H0 as="h1">ReusePlastics.org is not optimized for your browser.</H0>
          <P1>
            <Text italic>
              {' '}
              From Single Use to Reuse<sup>SM</sup>
            </Text>{' '}
            is a collaborative effort of industry innovators, primarily
            America’s plastic makers, seeking to end plastic waste.
          </P1>
          <P1>
            This site is not optimized for your current browser. Please use one
            the following to best experience our site: Chrome, Firefox, or
            Safari.{' '}
          </P1>
          <P1>
            <Text bold>
              To download the latest versions of optimized browsers, click any
              link below and follow installation instructions.{' '}
            </Text>
          </P1>
        </Copy>
        <BrowserTable>
          <tbody>
            <tr>
              <td>
                <BrowserImg src={imgFirefox} />
              </td>
              <td>
                <BrowserImg src={imgChrome} />
              </td>
              <td>
                <BrowserImg src={imgSafari} />
              </td>
            </tr>
            <tr>
              <td>
                <TextLink
                  text="Mozilla Firefox"
                  href="https://www.mozilla.org/en-US/"
                  openInNewWindow
                />
              </td>
              <td>
                <TextLink
                  text="Google Chrome"
                  href="https://www.google.com/chrome/"
                  openInNewWindow
                />
              </td>
              <td>
                <TextLink
                  text="Safari"
                  href="https://support.apple.com/downloads/safari"
                  openInNewWindow
                />
              </td>
            </tr>
          </tbody>
        </BrowserTable>
      </Text>
    </Layout>
  );
};
